import { type Menu as MenuType, type SubMenu as SubMenuType } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Image from 'next/image'
import Link from 'next/link'
import 'rc-menu/assets/index.css'
import { useEffect, useRef, useState } from 'react'
import ImageOptimize from '../ImageOptimize'

const DEFAULT_HIGHLIGHT = 0
interface CateDropdownProps {
  items: MenuType[]
  showIcon?: boolean
  className?: string
  popupClassName?: string
}
const CateDropdown = ({ items, className }: CateDropdownProps) => {
  const ref = useRef<HTMLInputElement>(null)
  const [childMegaMenu, setChildMegaMenu] = useState(items[0].children || [])

  const [height, setHeight] = useState<number | null>(null)
  const [width, setWidth] = useState<number | null>(null)
  useEffect(() => {
    const divW = document.querySelector('.headerMenu')
    if (divW instanceof HTMLDivElement) {
      setWidth(divW.offsetWidth)
    }
  }, [])
  useEffect(() => {
    const div = document.querySelector('.mgLeftArea')
    if (div instanceof HTMLDivElement) {
      setHeight(div.offsetHeight)
    }
  }, [])
  const checkMenuItemType = (e: MenuType | SubMenuType) => {
    switch (e?.type) {
      case 'productCat':
        return e?.slug + '.c' || '/'
      case 'link':
        return e?.url || '/'
      case 'post':
        return e?.slug + '.b' || '/'
      case 'postCat':
        return (e?.slug as string) || '/'
      case 'product':
        return (e?.slug as string) || '/'
      default:
        return e?.url || '/'
    }
  }
  return (
    <div
      className={`${className}`}
      style={{ minWidth: `${width}px` }}
      ref={ref}
    >
      <div className='flex w-full'>
        <div className='h-full max-w-[314px] flex-[0_0_314px] border-r-8 border-white-700 py-1'>
          <div className='mgLeftArea flex flex-col'>
            {items &&
              items.map((e, index) => (
                <div
                  key={index + e.menuType}
                  className='relative flex items-center gap-x-2 px-3 py-2.5'
                  onMouseEnter={() => setChildMegaMenu(e?.children)}
                >
                  <div className='min-w-[24px]'>
                    {e.image ? (
                      <div className='absolute left-[-8px] top-[50%] translate-y-[-50%]'>
                        <ImageOptimize
                          src={e.image}
                          width={54}
                          height={34}
                          alt={getTranslate(e.name)}
                          priority
                        />
                      </div>
                    ) : (
                      <ImageOptimize
                        src={e.icon}
                        width={24}
                        height={24}
                        alt={getTranslate(e.name)}
                        priority
                      />
                    )}
                  </div>
                  <div className='flex w-full whitespace-nowrap'>
                    <div className=''>
                      <Link
                        href={checkMenuItemType(e)}
                        className={`${
                          index === DEFAULT_HIGHLIGHT
                            ? 'text-red-500'
                            : 'text-black-400'
                        } cursor-pointer text-Headline font-bold  text-black-400 transition-all ease-in hover:text-blue-400`}
                      >
                        {getTranslate(e.name)}
                      </Link>
                      <div className='w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-mainBody text-black-400'>
                        {e.children &&
                          e.children
                            .filter(e => e.highlight)
                            .map((f, index) => (
                              <Link
                                href={checkMenuItemType(f)}
                                key={'lv-1-' + index}
                              >
                                <span
                                  key={index}
                                  className=' cursor-pointer hover:text-blue-400'
                                >
                                  {index > 0 && '  -  '}
                                  {getTranslate(f?.name)}
                                </span>
                              </Link>
                            ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div className='flex-1 p-1'>
          <div
            className='grid w-full grid-cols-4 gap-x-10 gap-y-8 overflow-y-auto px-5 py-2'
            style={{ maxHeight: `${(height as number) - 16}px` }}
          >
            {childMegaMenu &&
              childMegaMenu.map((r, ind) => (
                <div key={ind} className='flex-33'>
                  <Link href={checkMenuItemType(r)} key={'lv-2-' + ind}>
                    <span className='flex gap-1.5 text-base font-bold uppercase text-black-400'>
                      {getTranslate(r?.name)}
                      {r.image && (
                        <Image
                          width={24}
                          height={24}
                          alt={getTranslate(r.name) || ''}
                          src={r.image}
                          className='h-4 w-4'
                          priority
                        />
                      )}
                    </span>
                  </Link>
                  <div className='flex flex-col'>
                    {r.children &&
                      r.children.map((f, fIndex: number) => (
                        <Link
                          href={checkMenuItemType(f)}
                          key={'lv-3-' + fIndex}
                        >
                          <span className='cursor-pointer text-mainBody font-normal !leading-[20px] text-black-400 hover:text-blue-400'>
                            {getTranslate(f.name)}
                          </span>
                        </Link>
                      ))}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default CateDropdown
