/* eslint-disable @typescript-eslint/no-explicit-any */
import Keyword from '@/components/Keyword'
import ModalChooseLocation from '@/components/Popup/ModalChooseLocation'
import { useNotification, useStore } from '@/containers'
import { Footer } from '@/layouts/Footer'
import { Header } from '@/layouts/Header'
import { HeaderBottom } from '@/layouts/HeaderBotom'
import { type BannerList } from '@/types'
import dynamic from 'next/dynamic'
import { type ReactNode } from 'react'
const NotificationBar = dynamic(() => import('@/components/NotificationBar'), {
  ssr: false,
})
const OnDevelopment = dynamic(() => import('@/components/OnDevelopment'), {
  ssr: false,
})
type IMainProps = {
  meta?: ReactNode
  children: ReactNode
  hideFooter?: boolean
  banner?: BannerList[]
  bgColor?: string
  bottomMenu?: any
  style?: any
  page?: string
  className?: string
  backgroundImage?: string
}
const Main = (props: IMainProps) => {
  const { keywords } = useStore()
  const { systemConfig } = useNotification()
  const SnowFall = dynamic(() => import('@/components/Decorate/SnowFall'), {
    ssr: false,
  })
  return (
    <>
      {props.meta}
      <div className=' w-full text-dark-200 antialiased'>
        <Header page={props.page} />
        <HeaderBottom />
        <NotificationBar />
        <main
          style={
            props.page === 'homepage'
              ? {
                  backgroundColor:
                    systemConfig?.homepageBgColor &&
                    systemConfig?.homepageBgColor,
                  backgroundImage:
                    props?.backgroundImage && `url(${props?.backgroundImage})`,
                  backgroundRepeat: props?.backgroundImage && `no-repeat`,
                  backgroundSize: props?.backgroundImage && `100% auto`,
                  backgroundPosition: props?.backgroundImage && `bottom center`,
                  backgroundAttachment: props?.backgroundImage && `fixed`,
                }
              : props.style && props.style
          }
          className={`main-content pt-3 text-base ${
            props.bgColor ? props.bgColor : ''
          } ${props.className ? props.className : ''}`}
        >
          <div
            className={`mainWrapper  mx-auto w-full ${
              props.page === 'homepage' ? '' : 'container'
            }`}
          >
            {props.children}
          </div>
          {systemConfig && systemConfig.effectTetHoliday && <SnowFall />}
          {!props.hideFooter && <Keyword data={keywords} />}
        </main>
        {!props.hideFooter && <Footer page={props?.page || ''} />}
        <OnDevelopment />
        <ModalChooseLocation />
      </div>
    </>
  )
}

export { Main }
